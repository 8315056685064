import { AxiosRequestConfig, AxiosResponse } from "axios";
import http from "../HttpCommon";
import { notAuthorised } from "./Messages";
import { School } from "../types/schools/school";
import { SelfUser } from "../types/users/SelfUser";
import { InviteTeacher } from "../types/users/InviteTeacher";
import { AddTopic } from "../types/topics/AddTopic";
import { EditTopic } from "../types/topics/EditTopic";
import { Department } from "../types/departments/Department";
import { Question } from "../types/questions/Question";
import { Topic } from "../types/topics/Topic";
import { AddQuestion } from "../types/questions/AddQuestion";
import { EditQuestion } from "../types/questions/EditQuestion";
import {
  AddDepartment,
  IAddDepartment,
} from "../types/departments/AddDepartment";
import { EditDepartment } from "../types/departments/EditDepartment";
import { Constants } from "../Constants";
import { UserDataItem } from "../types/users/UserDataItem";
import { SingleDepartment } from "../types/departments/SingleDepartment";
import { DatabaseUsersPaged } from "../types/users/DatabaseUsersPaged";

const getConfig = async (
  token: string | undefined,
): Promise<AxiosRequestConfig> => {
  if (!token) {
    throw new Error(notAuthorised);
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const getSchools = async (token: string | undefined): Promise<School[]> => {
  let config = await getConfig(token);
  const result = await http.get<School[]>("/schools", config);
  return result.data;
};

const getUsers = async (
  schoolId: string,
  token: string | undefined,
  page: number,
): Promise<DatabaseUsersPaged> => {
  let config = await getConfig(token);
  let offset = (page - 1) * Constants.ITEMSPERPAGE;
  const result = await http.get<DatabaseUsersPaged>(
    `/users/${schoolId}?limit=${Constants.ITEMSPERPAGE}&offset=${offset}`,
    config,
  );
  return result.data;
};

const getSelfUser = async (token: string | undefined): Promise<SelfUser> => {
  let config = await getConfig(token);
  const result = await http.get<SelfUser>("/users/self", config);
  return result.data;
};

const addOrUpdateSelfData = async (
  data: UserDataItem,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post("/users/self/data", data, config);
};

const deleteSelfData = async (
  name: string,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/users/self/data?name=${name}`, config);
};

const inviteTeacher = async (
  invite: InviteTeacher,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post("users/teacher/invite", invite, config);
};

const deleteUser = async (
  userId: number,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/users/${userId}`, config);
};

const addDepartment = async (
  department: IAddDepartment,
): Promise<AxiosResponse> => {
  let config = await getConfig(department.token);
  return await http.post<AddDepartment>(
    "/departments",
    department.department,
    config,
  );
};

const updateDepartment = async (
  departmentId: string,
  department: EditDepartment,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.put<EditDepartment>(
    `/departments/${departmentId}`,
    department,
    config,
  );
};

const getDepartments = async (
  schoolId: string,
  token: string | undefined,
): Promise<Department[]> => {
  let config = await getConfig(token);
  let result = await http.get<Department[]>(
    `/schools/${schoolId}/departments`,
    config,
  );
  return result.data;
};

const getDepartment = async (
  departmentId: string,
  token: string | undefined,
): Promise<SingleDepartment> => {
  let config = await getConfig(token);
  let result = await http.get<SingleDepartment>(
    `/departments/${departmentId}`,
    config,
  );
  return result.data;
};

const deleteDepartment = async (
  depatmentId: number,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/departments/${depatmentId}`, config);
};

const getTopics = async (
  departmentId: string,
  token: string | undefined,
): Promise<Topic[]> => {
  let config = await getConfig(token);
  const result = await http.get<Topic[]>(
    `/departments/${departmentId}/topics`,
    config,
  );
  return result.data;
};

const getTopic = async (
  topicId: string,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.get<Topic>(`/topics/${topicId}`, config);
};

const addTopic = async (
  topic: AddTopic,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post<AddTopic>("/topics", topic, config);
};

const updateTopic = async (
  topicId: number,
  topic: EditTopic,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.put<EditTopic>(`/topics/${topicId}`, topic, config);
};

const deleteTopic = async (
  topicId: number,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/topics/${topicId}`, config);
};

const getQuestions = async (
  topicId: string,
  token: string | undefined,
): Promise<Question[]> => {
  let config = await getConfig(token);
  const result = await http.get<Question[]>(
    `/topics/${topicId}/questions`,
    config,
  );
  return result.data;
};

const addQuestion = async (
  question: AddQuestion,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.post<AddQuestion>("/questions", question, config);
};

const updateQuestion = async (
  questionId: number,
  question: EditQuestion,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.put<EditQuestion>(
    `/questions/${questionId}`,
    question,
    config,
  );
};

const deleteQuestion = async (
  questionId: number,
  token: string | undefined,
): Promise<AxiosResponse> => {
  let config = await getConfig(token);
  return await http.delete(`/questions/${questionId}`, config);
};

const ApiService = {
  getSchools,
  getUsers,
  getSelfUser,
  addOrUpdateSelfData,
  deleteSelfData,
  inviteTeacher,
  deleteUser,
  addDepartment,
  updateDepartment,
  getDepartments,
  getDepartment,
  deleteDepartment,
  getTopics,
  getTopic,
  addTopic,
  updateTopic,
  deleteTopic,
  getQuestions,
  addQuestion,
  updateQuestion,
  deleteQuestion,
};

export default ApiService;
