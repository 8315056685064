import { CircularProgress } from "@mui/material";

const LoadingComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="mt-3"
    >
      <CircularProgress />
    </div>
  );
};

const LoadingPageComponent = () => {
  return (
    <section className="pt-5 pb-5 bg-light mt-3">
      <LoadingComponent />
    </section>
  );
};

export { LoadingComponent, LoadingPageComponent };
