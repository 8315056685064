import { Grid, Link, Typography } from "@mui/material";
import { monocool } from "../services/Messages";

export const Footer = () => {
  return (
    <section className="pt-5 pb-5">
      <Grid container>
        <Grid item xs={12}>
          <Typography align="center">
            &copy;{" "}
            <Link
              color="grey.900"
              underline="hover"
              href="https://monocool.net"
              target="_blank"
              rel="noreferrer"
            >
              {monocool}
            </Link>{" "}
            {new Date().getFullYear()}
          </Typography>
        </Grid>
      </Grid>
    </section>
  );
};
