import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  gridClasses,
} from "@mui/x-data-grid";
import { Constants } from "../Constants";

interface DataTableProps {
  loading: boolean;
  disableColumnMenu: boolean;
  columns: GridColDef[];
  rows: any[];
  totalRows: number;
  paginationModel: GridPaginationModel;
  paginationModelChanged: (
    model: GridPaginationModel,
    details: GridCallbackDetails,
  ) => void;
}

export const DataTable = ({
  loading,
  disableColumnMenu,
  columns,
  paginationModel,
  paginationModelChanged,
  rows,
  totalRows,
}: DataTableProps) => {
  return (
    <DataGrid
      sx={{
        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
          outline: "transparent",
        },
        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
          {
            outline: "none",
          },
      }}
      autoHeight
      disableRowSelectionOnClick
      disableColumnMenu={disableColumnMenu}
      columns={columns}
      loading={loading}
      rows={rows}
      rowCount={totalRows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={paginationModelChanged}
      pageSizeOptions={[Constants.ITEMSPERPAGE]}
      onCellClick={(params, event) => {
        event.stopPropagation();
      }}
      onRowClick={(params, event) => {
        event.stopPropagation();
      }}
    />
  );
};
