import { useState, ChangeEvent, FormEvent } from "react";

export const HandleForm = (callback: any, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name.includes("cb-")) {
      // This is a hack for setting checked for a checkbox
      // The "name" for a checkbox must begin with "cb-" for this to work
      setValues({ ...values, [event.target.name]: event.target.checked });
    } else {
      // This is for every input other than a checkbox
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await callback();
  };

  return {
    onChange,
    onSubmit,
    values,
  };
};
