import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AlertColor, Stack, Button, Box } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import {
  forgotPasswordQuestion,
  backToLogin,
  altTextLogo,
} from "../../services/Messages";
import {
  MessageComponent,
  MessageProps,
} from "../../components/MessageComponent";
import { LoginForm } from "./components/LoginForm";
import { ForgotPasswordForm } from "./components/ForgotPasswordForm";
import { LoadingComponent } from "../../components/LoadingComponent";

export const LoginPage = () => {
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [showForgotPasswordForm, setShowForgotPasswordForm] =
    useState<boolean>(false);

  const authContext = useContext(AuthContext);

  const getContent = () => {
    return (
      <Box sx={{ m: 2 }}>
        <Stack spacing={2}>
          <img
            className="mb-4"
            width="300"
            src={require("../../assets/logos/logo.png")}
            alt={altTextLogo}
          />
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              variant={message.variant}
            />
          )}
          {!showForgotPasswordForm && (
            <>
              <LoginForm onErrorCallback={onFormMessage} />
              <Button variant="text" onClick={onToggle}>
                {forgotPasswordQuestion}
              </Button>
            </>
          )}
          {showForgotPasswordForm && (
            <>
              <ForgotPasswordForm
                onErrorCallback={onFormMessage}
                onSuccessCallback={onFormMessage}
              />
              <Button variant="text" onClick={onToggle}>
                {backToLogin}
              </Button>
            </>
          )}
        </Stack>
      </Box>
    );
  };

  const onToggle = () => {
    setMessage({ message: "", variant: "info" });
    setShowForgotPasswordForm(!showForgotPasswordForm);
  };

  const onFormMessage = (errorMessage: string, variant: AlertColor) => {
    setMessage({ message: errorMessage, variant: variant });
  };

  return (
    <>
      {authContext.loading ? (
        <LoadingComponent />
      ) : authContext.user ? (
        <Navigate to="/" replace />
      ) : (
        getContent()
      )}
    </>
  );
};
