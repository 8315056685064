import { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LockIcon from "@mui/icons-material/Lock";
import ApiService from "../../../services/ApiService";
import {
  failedToLoadDashboard,
  manage,
  manageDescription,
} from "../../../services/Messages";
import { School } from "../../../types/schools/school";
import { AuthContext } from "../../../context/AuthContext";
import { LoadingComponent } from "../../../components/LoadingComponent";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { CreateGameCard } from "./CreateGameCard";
import { ManageUsersCard } from "./ManageUsersCard";

export const DashboardSa = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [schools, setSchools] = useState<Array<School>>([]);
  const [school, setSchool] = useState<School | null>(null);

  const {
    loading: authLoading,
    userCredential,
    changeSchoolId,
  } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async (schoolId: string, token: string) => {
      const response = await ApiService.getSchools(token);
      const schools: School[] = response;
      setSchools(schools);

      if (schools.length === 0) {
        changeSchoolId("");
      } else {
        const school = schools.find((s) => s.id.toString() === schoolId);
        if (school) {
          setSchool(school);
        } else {
          setSchool(schools[0]);
          const id = schools[0].id.toString();
          changeSchoolId(id);
        }
      }
    };

    if (!authLoading && userCredential) {
      fetchData(userCredential.schoolId, userCredential.token)
        .catch((e) => {
          setMessage({ message: failedToLoadDashboard, variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authLoading, userCredential, changeSchoolId]);

  const onSchoolChanged = (event: SelectChangeEvent<unknown>) => {
    const schoolId = Number(event.target.value);
    const school = schools.find((s) => s.id === schoolId);
    if (school) {
      setSchool(school);
      changeSchoolId(school.id.toString());
    } else {
      setSchool(null);
      changeSchoolId("");
    }
  };

  const getSchoolList = () => {
    return (
      <FormControl fullWidth>
        <Select onChange={onSchoolChanged} value={school ? school.id : 1}>
          {schools.map((school, index) => {
            return (
              <MenuItem key={index} value={school.id}>
                {school.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const getManageDepartmentsCard = () => {
    return (
      <Box sx={{ boxShadow: 2, height: "100%" }}>
        <Card variant="outlined" sx={{ height: "100%" }}>
          <CardContent>
            <CardActionArea href="/depts" disableRipple disableTouchRipple>
              <Stack spacing={1} alignContent="center" textAlign="center">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <LibraryBooksIcon
                    style={{ fontSize: 40, color: "#22B8DC" }}
                  />
                </Box>
                <Typography variant="h5">{manage}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {manageDescription}
                </Typography>
              </Stack>
            </CardActionArea>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const getContent = () => {
    return (
      <>
        {message.message.length > 0 && (
          <MessageComponent
            message={message.message}
            href={message.href}
            variant={message.variant}
          />
        )}

        <Grid item md={6} sm={6} xs={6}>
          {getSchoolList()}
        </Grid>
        <Grid item md={1} sm={1} xs={1}>
          {school && school.isLocked && <LockIcon style={{ fontSize: 20 }} />}
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          {getManageDepartmentsCard()}
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CreateGameCard />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <ManageUsersCard />
        </Grid>
      </>
    );
  };

  return <>{loading ? <LoadingComponent /> : getContent()}</>;
};
