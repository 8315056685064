import { User } from "firebase/auth";
import { DatabaseUser } from "../../../types/users/DatabaseUser";
import { KeyboardEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  cancel,
  confirmDeleteUser,
  userEmailDoesNotMatch,
  doDelete,
  failedToDeleteUser,
  userLabel,
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import { LoadingComponent } from "../../../components/LoadingComponent";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";

interface DeleteUserModalProps {
  show: boolean;
  user: User;
  dbUser: DatabaseUser | null;
  onCancelCallback: () => void;
  onSubmitCallback: () => void;
}

export const DeleteUserModal = ({
  show,
  user,
  dbUser,
  onCancelCallback,
  onSubmitCallback,
}: DeleteUserModalProps) => {
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [confirmUserEmail, setConfirmUserEmail] = useState<string>("");

  useEffect(() => {
    setMessage({ message: "", variant: "info" });
    if (!dbUser) {
      setMessage({ message: failedToDeleteUser, variant: "error" });
    }
  }, [dbUser]);

  const getContent = () => {
    return (
      <>
        <Typography variant="body1">{confirmDeleteUser}</Typography>
        <FormControl fullWidth>
          <TextField
            autoFocus
            required
            id="email"
            type="email"
            variant="outlined"
            name="email"
            onChange={(e) => setConfirmUserEmail(e.target.value)}
          />
        </FormControl>
      </>
    );
  };

  const onDelete = async () => {
    try {
      setMessage({ message: "", variant: "info" });
      if (confirmUserEmail !== dbUser!.email) {
        setMessage({ message: userEmailDoesNotMatch, variant: "error" });
        return;
      }
      setIsBusy(true);
      const token = await user.getIdToken();
      await ApiService.deleteUser(dbUser!.id, token);
      setIsBusy(false);
      onSubmitCallback();
    } catch (e) {
      setMessage({ message: failedToDeleteUser, variant: "error" });
      setIsBusy(false);
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onDelete();
    }
  };

  return (
    <Dialog open={show} onKeyUp={onKeyUp} onClose={onCancelCallback}>
      <DialogTitle>{`${doDelete} ${userLabel}: ${dbUser?.email}?`}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ m: 1 }}>
          {message.message.length > 0 && (
            <MessageComponent
              message={message.message}
              variant={message.variant}
            />
          )}
          {isBusy ? <LoadingComponent /> : getContent()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={isBusy} onClick={onCancelCallback}>
          {cancel}
        </Button>
        <Button disabled={!dbUser || isBusy} onClick={onDelete}>
          {doDelete}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
