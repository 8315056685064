import { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  Box,
  Stack,
  Card,
  CardActionArea,
  Button,
  CardContent,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import {
  dashboardTitle,
  departmentsTitle,
  doEdit,
  doDelete,
  failedToGetDepartments,
  addDepartmentTitle,
} from "../../services/Messages";
import { Department } from "../../types/departments/Department";
import {
  MessageComponent,
  MessageProps,
} from "../../components/MessageComponent";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { LoadingComponent } from "../../components/LoadingComponent";
import { AddOrEditDepartmentModal } from "./components/AddOrEditDepartmentModal";
import { DeleteDepartmentModal } from "./components/DeleteDepartmentModal";

export const DepartmentsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [departments, setDepartments] = useState<Array<Department>>([]);
  const [schoolId, setSchoolId] = useState<string>("");
  const [department, setDepartment] = useState<Department | null>(null);
  const [showAmendDepartment, setShowAmendDepartment] =
    useState<boolean>(false);
  const [showDeleteDepartment, setShowDeleteDepartment] =
    useState<boolean>(false);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async (token: string, schoolId: string) => {
      setSchoolId(schoolId);
      const response = await ApiService.getDepartments(schoolId, token);
      setDepartments(response);
    };

    if (!authContext.loading && authContext.userCredential) {
      fetchData(
        authContext.userCredential.token,
        authContext.userCredential.schoolId,
      )
        .then(() => {
          setMessage({ message: "", variant: "info" });
          setLoading(false);
        })
        .catch((e) => {
          setMessage({ message: failedToGetDepartments, variant: "error" });
          setLoading(false);
        });
    }
  }, [authContext.loading, authContext.userCredential]);

  const onAddDepartment = () => {
    setDepartment(null);
    setShowAmendDepartment(true);
  };

  const onCancelAmendDepartment = () => {
    setDepartment(null);
    setShowAmendDepartment(false);
  };

  const onDepartmentAmended = (department: Department, wasAdded: boolean) => {
    if (wasAdded) {
      let newDepartments = [...departments, department];
      newDepartments = newDepartments.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setDepartments(newDepartments);
      setShowAmendDepartment(false);
    } else {
      let newDepartments = [...departments];
      const departmentsIndex = newDepartments.findIndex(
        (d) => d.id === department.id,
      );
      newDepartments[departmentsIndex] = department;
      newDepartments = newDepartments.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setDepartments(newDepartments);

      setDepartment(null);
      setShowAmendDepartment(false);
    }
  };

  const onEditDepartment = (department: Department) => {
    setDepartment(department);
    setShowAmendDepartment(true);
  };

  const onDeleteDepartment = (department: Department) => {
    setDepartment(department);
    setShowDeleteDepartment(true);
  };

  const onCancelDeleteDepartment = () => {
    setDepartment(null);
    setShowDeleteDepartment(false);
  };

  const onDepartmentDeleted = (department: Department) => {
    const ds = departments.filter((d) => d.id !== department.id);
    setDepartments(ds);
    setDepartment(null);
    setShowDeleteDepartment(false);
  };

  const getContent = () => {
    return (
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          crumbs={[
            { name: dashboardTitle, path: "/dashboard" },
            { name: departmentsTitle, path: "#" },
          ]}
        />

        <AddOrEditDepartmentModal
          show={showAmendDepartment}
          department={department}
          schoolId={schoolId}
          user={authContext.user!}
          onSubmitCallback={onDepartmentAmended}
          onCancelCallback={onCancelAmendDepartment}
        />

        <DeleteDepartmentModal
          show={showDeleteDepartment}
          department={department}
          user={authContext.user!}
          onCancelCallback={onCancelDeleteDepartment}
          onSubmitCallback={onDepartmentDeleted}
        />

        <Typography variant="h4" gutterBottom>
          {departmentsTitle}
        </Typography>
        {message.message.length > 0 && (
          <MessageComponent
            message={message.message}
            variant={message.variant}
          />
        )}

        <Grid container rowSpacing={2} columnSpacing={2}>
          {getColumnsForRow()}
        </Grid>

        <Box sx={{ mt: 2 }}>
          {authContext.userCredential?.isAdmin && (
            <Button variant="contained" onClick={onAddDepartment}>
              <AddIcon />
              &nbsp;{addDepartmentTitle}
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  const getCard = (department: Department) => {
    return (
      <Box sx={{ boxShadow: 2, height: "100%" }}>
        <Card
          key={`card-${department.id}`}
          variant="outlined"
          sx={{ height: "100%" }}
        >
          <CardContent>
            <CardActionArea
              href={`/depts/${department.id}/topics`}
              disableRipple
              disableTouchRipple
            >
              <Box sx={{ m: 2 }}>
                <Stack alignContent="center" textAlign="center">
                  <Typography variant="h5">{department.name}</Typography>
                </Stack>
              </Box>
            </CardActionArea>
            {authContext.userCredential?.isAdmin && (
              <Stack alignContent="center">
                <Button
                  variant="text"
                  onClick={() => onEditDepartment(department)}
                >
                  {doEdit}
                </Button>
                <Button
                  variant="text"
                  onClick={() => onDeleteDepartment(department)}
                >
                  {doDelete}
                </Button>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const getColumnsForRow = () => {
    return departments.map((department) => {
      return (
        <Grid item md={4} sm={12} xs={12} key={`col-${department.id}`}>
          {getCard(department)}
        </Grid>
      );
    });
  };

  return <>{loading ? <LoadingComponent /> : getContent()}</>;
};
