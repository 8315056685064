import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  manageUsers,
  manageUsersDescription,
} from "../../../services/Messages";

export const ManageUsersCard = () => {
  return (
    <Box sx={{ boxShadow: 2, height: "100%" }}>
      <Card variant="outlined" sx={{ height: "100%" }}>
        <CardContent>
          <CardActionArea href="/users" disableRipple disableTouchRipple>
            <Stack spacing={1} alignContent="center" textAlign="center">
              <Box display="flex" justifyContent="center" alignItems="center">
                <PeopleAltIcon style={{ fontSize: 40, color: "#22B8DC" }} />
              </Box>
              <Typography variant="h5">{manageUsers}</Typography>
              <Typography variant="body2" color="text.secondary">
                {manageUsersDescription}
              </Typography>
            </Stack>
          </CardActionArea>
        </CardContent>
      </Card>
    </Box>
  );
};
