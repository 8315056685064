import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridColDef, getGridStringOperators } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  add,
  failedToGetTopics,
  addTopicGetStarted,
  doDelete,
  doEdit,
  numberOfTermsLabel,
  terms,
  topicLabel,
  yearsLabel,
  topicDeleted,
  topicAdded,
  topicEdited,
  categoryLabel,
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import { DataTableClientSide } from "../../../components/DataTableClientSide";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { Topic } from "../../../types/topics/Topic";
import { Year } from "../../../types/departments/Year";
import { AddOrEditTopicModal } from "./AddOrEditTopicModal";
import { DeleteTopicModal } from "./DeleteTopicModal";

interface TopicsTableProps {
  schoolId: string;
  departmentId: string;
  years: Array<Year>;
  user: User;
}

function TopicsTable({
  schoolId,
  departmentId,
  years,
  user,
}: TopicsTableProps): JSX.Element {
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [topics, setTopics] = useState<Array<Topic>>([]);
  const [topic, setTopic] = useState<Topic | null>(null);
  const [showAmendTopic, setShowAmendTopic] = useState<boolean>(false);
  const [showDeleteTopic, setShowDeleteTopic] = useState<boolean>(false);

  const navigate = useNavigate();

  const onEditTopic = (topic: Topic) => {
    setMessage({ message: "", variant: "info" });
    setTopic(topic);
    setShowAmendTopic(true);
    setMessage({ message: topicEdited, variant: "success" });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: topicLabel,
      width: 250,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "category",
      headerName: categoryLabel,
      width: 250,
      sortable: false,
      filterable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(({ value }) =>
        ["contains", "isEmpty", "isNotEmpty"].includes(value)
      ),
    },
    {
      field: "years",
      headerName: yearsLabel,
      width: 120,
      sortable: false,
      filterable: true,
      hideable: false,
      filterOperators: getGridStringOperators().filter(({ value }) =>
        ["contains"].includes(value)
      ),
    },
    {
      field: "numQuestions",
      headerName: numberOfTermsLabel,
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
    },
    {
      field: "view",
      headerName: "",
      width: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const top: Topic = params.row as Topic;
        return (
          <Button
            variant="contained"
            onClick={(event) => {
              onViewTerms(top);
            }}
          >
            {terms}
          </Button>
        );
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const top: Topic = params.row as Topic;
        return (
          <Button
            variant="contained"
            onClick={(event) => {
              onEditTopic(top);
            }}
          >
            <EditIcon />
            &nbsp;{doEdit}
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 120,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const top: Topic = params.row as Topic;
        return (
          <Button
            variant="contained"
            onClick={(event) => {
              onDeleteTopic(top);
            }}
          >
            <DeleteIcon />
            &nbsp;{doDelete}
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchTopics = async () => {
      const token = await user.getIdToken();
      const topics = await ApiService.getTopics(departmentId, token);
      setTopics(topics);
    };

    fetchTopics()
      .then(() => {
        setMessage({ message: "", variant: "info" });
        setDataLoading(false);
      })
      .catch((e) => {
        setMessage({ message: failedToGetTopics, variant: "error" });
        setDataLoading(false);
      });
  }, [departmentId, user]);

  const onViewTerms = (topic: Topic) => {
    navigate(`/depts/${departmentId!}/topics/${topic.id}/terms`);
  };

  const onDeleteTopic = (topic: Topic) => {
    setMessage({ message: "", variant: "info" });
    setTopic(topic);
    setShowDeleteTopic(true);
  };

  const onCancelDeleteTopic = () => {
    setTopic(null);
    setShowDeleteTopic(false);
  };

  const onTopicDeleted = (topic: Topic) => {
    const ts = topics.filter((t) => t.id !== topic.id);
    setTopics(ts);
    setTopic(null);
    setShowDeleteTopic(false);
    setMessage({ message: topicDeleted, variant: "success" });
  };

  const onAddTopic = () => {
    setMessage({ message: "", variant: "info" });
    setTopic(null);
    setShowAmendTopic(true);
  };

  const onCancelAmendTopic = () => {
    setShowAmendTopic(false);
  };

  const onTopicAmended = (topic: Topic, wasAdded: boolean) => {
    if (wasAdded) {
      setTopics([...topics, topic]);
      setTopic(null);
      setShowAmendTopic(false);
      setMessage({ message: topicAdded, variant: "success" });
    } else {
      const newTopics = [...topics];
      const topicIndex = newTopics.findIndex((t) => t.id === topic.id);
      newTopics[topicIndex] = topic;

      setTopics(newTopics);
      setTopic(null);
      setShowAmendTopic(false);
    }
  };

  return (
    <>
      <AddOrEditTopicModal
        show={showAmendTopic}
        topic={topic}
        schoolId={schoolId}
        departmentId={departmentId}
        years={years}
        user={user}
        onSubmitCallback={onTopicAmended}
        onCancelCallback={onCancelAmendTopic}
      />

      <DeleteTopicModal
        show={showDeleteTopic}
        topic={topic}
        user={user}
        onSubmitCallback={onTopicDeleted}
        onCancelCallback={onCancelDeleteTopic}
      />

      {topics.length === 0 && (
        <Typography variant="body1">{addTopicGetStarted}</Typography>
      )}
      {message.message.length > 0 && (
        <MessageComponent message={message.message} variant={message.variant} />
      )}

      <DataTableClientSide
        loading={dataLoading}
        disableColumnMenu={false}
        filterable={true}
        columns={columns}
        rows={topics}
      />

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" onClick={onAddTopic}>
          <AddIcon />
          &nbsp;{add}
        </Button>
      </Box>
    </>
  );
}

export default TopicsTable;
