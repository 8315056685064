import { Grid, Alert, AlertColor } from "@mui/material";
import Link from "@mui/material/Link";

export interface MessageProps {
  message: string;
  href?: string;
  variant: AlertColor;
}

export const MessageComponent = ({ message, href, variant }: MessageProps) => {
  return (
    <Grid item md={12} sm={12} xs={12}>
      {href && (
        <Alert className="mt-3 mb-3" severity={variant}>
          <Link href={href} color="grey.900">
            {message}
          </Link>
        </Alert>
      )}
      {!href && (
        <Alert className="mt-3 mb-3" severity={variant}>
          {message}
        </Alert>
      )}
    </Grid>
  );
};
