import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ApiService from "../../../services/ApiService";
import {
  doSet,
  cancel,
  doSetDefaultDepartment,
  failedToSetDefaultDepartment,
  failedToGetDepartments,
  noDefaultDepartment,
} from "../../../services/Messages";
import { Constants } from "../../../Constants";
import { Department } from "../../../types/departments/Department";
import {
  MessageComponent,
  MessageProps,
} from "../../../components/MessageComponent";
import { LoadingComponent } from "../../../components/LoadingComponent";

interface SetDefaultDepartmentModalProps {
  show: boolean;
  firebaseUser: User | null;
  defaultDepartmentId: string;
  schoolId: string | undefined;
  onSubmitCallback: (department: Department | null) => void;
  onCancelCallback: () => void;
}

export const SetDefaultDepartmentModal = ({
  show,
  firebaseUser,
  defaultDepartmentId,
  schoolId,
  onSubmitCallback,
  onCancelCallback,
}: SetDefaultDepartmentModalProps) => {
  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [message, setMessage] = useState<MessageProps>({
    message: "",
    variant: "info",
  });
  const [departments, setDepartments] = useState<Array<Department>>([]);
  const [departmentId, setDepartmentId] = useState<string>(defaultDepartmentId);

  useEffect(() => {
    const fetchData = async (schoolId: string) => {
      setMessage({ message: "", variant: "info" });
      setDepartmentId(defaultDepartmentId);
      if (show) {
        const token = await firebaseUser?.getIdToken();
        const response = await ApiService.getDepartments(schoolId, token);
        setDepartments(response);
      }
    };

    if (!schoolId) {
      setMessage({ message: failedToGetDepartments, variant: "error" });
      setIsBusy(false);
      return;
    }

    fetchData(schoolId)
      .catch((e) => {
        setMessage({ message: failedToGetDepartments, variant: "error" });
      })
      .finally(() => {
        setIsBusy(false);
      });
  }, [defaultDepartmentId, firebaseUser, schoolId, show]);

  const onDepartmentChanged = (event: SelectChangeEvent<unknown>) => {
    const departmentId = Number(event.target.value);
    const department = departments.find((d) => d.id === departmentId);
    if (department) {
      setDepartmentId(department.id.toString());
    } else {
      setDepartmentId("0");
    }
  };

  const getContent = () => {
    return (
      <FormControl fullWidth>
        <Select
          onChange={onDepartmentChanged}
          value={departmentId.length === 0 ? "0" : departmentId}
        >
          <MenuItem value="0">{noDefaultDepartment}</MenuItem>
          {departments.map((department, index) => {
            return (
              <MenuItem key={index} value={department.id}>
                {department.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const changeDefaultDepartment = async () => {
    const token = await firebaseUser?.getIdToken();
    await ApiService.addOrUpdateSelfData(
      {
        name: Constants.DEFAULTDEPARTMENTIDSETTINGNAME,
        value: departmentId,
      },
      token,
    );
  };

  const clearDefaultDepartment = async () => {
    const token = await firebaseUser?.getIdToken();
    await ApiService.deleteSelfData(
      Constants.DEFAULTDEPARTMENTIDSETTINGNAME,
      token,
    );
  };

  const onSubmit = async () => {
    try {
      if (departmentId === "0") {
        setIsBusy(true);
        await clearDefaultDepartment();
        setIsBusy(false);
        onSubmitCallback(null);
      } else {
        const department = departments.find(
          (d) => d.id.toString() === departmentId,
        );
        if (!department) {
          setMessage({
            message: failedToSetDefaultDepartment,
            variant: "error",
          });
          return;
        }
        setIsBusy(true);
        await changeDefaultDepartment();
        setIsBusy(false);
        onSubmitCallback(department);
      }
    } catch (e) {
      setIsBusy(false);
      setMessage({ message: failedToSetDefaultDepartment, variant: "error" });
    }
  };

  const onCancel = () => {
    setMessage({ message: "", variant: "info" });
    onCancelCallback();
  };

  return (
    <Dialog open={show} onClose={onCancel}>
      <DialogTitle>{doSetDefaultDepartment}</DialogTitle>
      <DialogContent>
        {message.message.length > 0 && (
          <MessageComponent
            message={message.message}
            variant={message.variant}
          />
        )}
        {isBusy ? <LoadingComponent /> : getContent()}
      </DialogContent>
      <DialogActions>
        <Button disabled={isBusy} color="secondary" onClick={onCancel}>
          {cancel}
        </Button>
        <Button
          disabled={isBusy || departments.length === 0}
          onClick={onSubmit}
        >
          {doSet}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
