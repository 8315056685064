import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import { useState } from "react";
import { Constants } from "../Constants";
import { DataTableToolbar } from "./DataTableToolbar";

interface DataTableClientSideProps {
  loading: boolean;
  disableColumnMenu: boolean;
  filterable: boolean;
  columns: GridColDef[];
  rows: any[];
}

export const DataTableClientSide = ({
  loading,
  disableColumnMenu,
  filterable,
  columns,
  rows,
}: DataTableClientSideProps) => {
  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null);

  return (
    <DataGrid
      sx={{
        [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
          outline: "transparent",
        },
        [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
          {
            outline: "none",
          },
      }}
      slots={{
        toolbar: filterable ? DataTableToolbar : null,
      }}
      slotProps={{
        panel: filterable
          ? {
              anchorEl: filterButtonEl,
            }
          : undefined,
        toolbar: filterable
          ? {
              setFilterButtonEl,
            }
          : undefined,
      }}
      initialState={{
        pagination: {
          paginationModel: { pageSize: Constants.ITEMSPERPAGE },
        },
      }}
      autoHeight
      disableRowSelectionOnClick
      disableColumnMenu={disableColumnMenu}
      columns={columns}
      loading={loading}
      rows={rows}
      pageSizeOptions={[Constants.ITEMSPERPAGE]}
      onCellClick={(params, event) => {
        event.stopPropagation();
      }}
      onRowClick={(params, event) => {
        event.stopPropagation();
      }}
    />
  );
};
